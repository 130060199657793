import { render, staticRenderFns } from "./PostGrid.vue?vue&type=template&id=773ab336"
import script from "./PostGrid.vue?vue&type=script&lang=js"
export * from "./PostGrid.vue?vue&type=script&lang=js"
import style0 from "./PostGrid.vue?vue&type=style&index=0&id=773ab336&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/vercel/path0/components/elements/Button.vue').default,MyFavoritesBtn: require('/vercel/path0/components/elements/MyFavoritesBtn.vue').default,MarkAsFavourite: require('/vercel/path0/components/elements/MarkAsFavourite.vue').default,Media: require('/vercel/path0/components/elements/Media/index.vue').default,ArticleInfos: require('/vercel/path0/components/elements/ArticleInfos.vue').default,Post: require('/vercel/path0/components/elements/Post.vue').default,Cta: require('/vercel/path0/components/elements/Cta.vue').default,Section: require('/vercel/path0/components/elements/Section.vue').default})
