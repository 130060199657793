
import { mapGetters } from 'vuex';
import getBlogPosts from '../../queries/getBlogPosts';
import getOrderedBlogPosts from '../../queries/getOrderedBlogPosts';
import getBlogPostsByCategories from '../../queries/getBlogPostsByCategories';
import getFavouritesBlogPostQuery from '../../queries/getFavouritesBlogPostQuery';
import getBlogPostsByTitle from '../../queries/getBlogPostsByTitle';
import getMostLikedBlogPosts from '../../queries/getMostLikedBlogPosts';

export default {
  // difference between throttle and debounce
  // https://stackoverflow.com/questions/25991367/difference-between-throttling-and-debouncing-a-function
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      screenWidth: 0,
      // on init, we want to display the featured grid
      isFeaturedGridActive: true,
      isSearchResultGridActive: false,
      isCategoryFilterOpen: false,
      isTextSearchOpen: false,
      showMoreCategories: false,
      activeCategories: [],
      urlFilter: '',
      searchTerm: '',
      orderBy: null,
      dropDownOptions: [
        { value: 'desc', label: this.$text('first-published') },
        { value: 'asc', label: this.$text('last-published') },
        { value: 'most-liked', label: this.$text('most-liked') }
      ],
      showDropdownList: false,
      sortBy: this.$text('last-published'),
      postList: [],
      firstRow: [],
      postBeforeCta: [],
      postAfterCta: [],
      postsSearchResult: [],
      // This is a workournd to load only 9 post after initial load.
      // On the first load, we need 11 post 2 for first row , 6 before CTA and the other 3 after CTA
      minPost: 2,
      maxPost: 11,
      amountOfPostPerLoad: 9,
      debounceTextSearch: null,
      debounceStickyFilterBar: null,
      isLg: null,
      initFilterRect: null,
      filterDom: null,
      featuredGridDom: null
    };
  },
  async fetch() {
    await this.initPostGrid();
  },
  computed: {
    ...mapGetters({
      locale: 'navigation/locale',
      postCount: 'settings/postCount',
      categories: 'settings/categories',
      isFavoriteFilterActivated: 'ui/isFavoriteFilterActivated'
    }),
    shownCategories() {
      if (this.isLg) {
        return this.categories;
      }
      // Md, Sm here
      if (this.showMoreCategories) {
        return this.categories;
      }

      return this.categories.slice(0, 5);
    },
    postsAreFinished() {
      const totalPostLoaded =
        this.firstRow.length + this.postBeforeCta.length + this.postAfterCta.length;

      if (this.postCount > totalPostLoaded) {
        return false;
      } else {
        return true;
      }
    }
  },
  watch: {
    isFavoriteFilterActivated() {
      if (this.isFavoriteFilterActivated) {
        this.resetTextResearch();
        this.resetCategoryFilters();
        this.onShowFavourites();
      } else {
        // reset filters
        this.resetTextResearch();
        this.resetCategoryFilters();
        this.showFeaturedGrid();
        this.recalculatePostGridScrollPosition();
      }
    }
  },
  created() {
    this.urlFilter = this.$route.query.f;
    if (this.urlFilter) {
      const match = this.categories.find((c) => c._id === this.urlFilter);
      this.$nextTick(() => this.onSelectCategory(match));
    }
  },
  mounted() {
    this.filterDom = document.getElementById('filters');
    this.featuredGridDom = document.getElementById('featured-grid');
    this.checkViewport();

    if (this.urlFilter) {
      setTimeout(() => this.recalculatePostGridScrollPosition(), 500);
    }

    if (this.isFavoriteFilterActivated) {
      this.resetTextResearch();
      this.resetCategoryFilters();
      this.onShowFavourites();
    }

    let originalFilterPosition;
    this.$nextTick(() => {
      this.initFilterRect = this.filterDom.getBoundingClientRect();
      originalFilterPosition = this.initFilterRect.top;
    });

    window.addEventListener('scroll', (e) => {
      if (window.scrollY > originalFilterPosition) {
        this.filterDom.classList.add(
          'fixed',
          'top-0',
          'left-1/2',
          '-translate-x-1/2',
          'md:px-[theme(grid.gridXMarginDesktop)]',
          '3xl:container'
        );
        this.filterDom.classList.remove('mx-[-24px]');
        this.featuredGridDom.classList.add('lg:mt-[170px]');
      } else {
        this.filterDom.classList.remove(
          'fixed',
          'top-0',
          'left-1/2',
          '-translate-x-1/2',
          'md:px-[theme(grid.gridXMarginDesktop)]',
          '3xl:container'
        );
        this.filterDom.classList.add('mx-[-24px]');
        this.featuredGridDom.classList.remove('lg:mt-[170px]');
      }
    });
  },
  methods: {
    checkViewport() {
      this.isLg = window.innerWidth > 1023;
    },
    // Loading posts
    async initPostGrid() {
      const query = getBlogPosts(this.locale);
      this.postList = await this.$sanity.fetch(query);
      if (this.data.featuredPost) {
        let featuredPost;
        const featuredPostIndex = this.postList
          .map((p) => p._id)
          .indexOf(this.data.featuredPost._ref);

        // we have a featured post, but we don't have its info on the first load.
        // We need to load it afterwards.
        if (featuredPostIndex === -1) {
          const query = `*[_id == "${this.data.featuredPost._ref}"][0]
            {_id, author->{name}, publicationDate, title, url, readingTime, excerpt,
            "teaserImage": {"metadata": teaserImage.refImage->{"asset": upload.asset->,
            "crop": upload.crop, "hotspot": upload.hotspot},
            "alt": teaserImage.alt},, postCategories[]->{"label": entry.key}}`;
          featuredPost = await this.$sanity.fetch(query);
          this.firstRow.push(featuredPost, ...this.postList.splice(0, 1));
        } else {
          // from postList, we should remove the featured post
          // we save the featured post in our variable,
          // and remove it from the postList array
          const featuredPost = {
            ...this.postList.splice(featuredPostIndex, 1)[0]
          };

          this.firstRow.push(featuredPost, ...this.postList.splice(0, 1));
        }
      } else {
        // no featured post, take the first two result and set it in the first row.
        this.firstRow.push(
          {
            excerpt: this.data.excerpt,
            ...this.postList.splice(0, 1)[0]
          },
          ...this.postList.splice(0, 1)
        );
      }

      // let's split post between before after cta
      this.postBeforeCta.push(...this.postList.splice(0, 6));
      this.postAfterCta.push(...this.postList);

      this.showFeaturedGrid();
    },
    async fetchPosts($state) {
      if (this.postsAreFinished) {
        $state.complete();
        return;
      }

      let query;
      this.minPost += this.amountOfPostPerLoad;
      this.maxPost += this.amountOfPostPerLoad;
      const pagination = `${this.minPost}...${this.maxPost}`;
      if (this.data.featuredPost) {
        query = getBlogPosts(this.locale, pagination, this.data.featuredPost._ref);
      } else {
        query = getBlogPosts(this.locale, pagination);
      }
      const posts = await this.$sanity.fetch(query);

      if (posts.length > 0) {
        this.postAfterCta.push(...posts);
        $state.loaded();
      } else {
        $state.complete();
      }
    },
    // filter bar events
    async onOrderSelected(event, index) {
      event.stopPropagation();
      this.resetFavourites();
      this.showDropdownList = !this.showDropdownList;
      this.sortBy = this.dropDownOptions[index].label;
      this.orderBy = this.dropDownOptions[index].value;

      let query;
      if (this.orderBy === 'most-liked') {
        query = getMostLikedBlogPosts(this.locale);
      } else {
        query = getOrderedBlogPosts(this.locale, this.orderBy);
      }

      this.postsSearchResult = [...(await this.$sanity.fetch(query))];

      this.showSearchResults();
      this.recalculatePostGridScrollPosition();
    },
    async onSelectCategory(category) {
      // we force reset all the other type of filters
      this.resetSearchResults();
      this.resetTextResearch();
      this.resetFavourites();

      const index = this.activeCategories.findIndex((cat) => cat._id === category._id);
      if (index === -1) {
        this.activeCategories.push(category);
      } else {
        this.activeCategories.splice(index, 1);
      }

      if (this.activeCategories.length === 0) {
        // reset URL params if no category is selected
        if (this.$route.query.f) {
          history.replaceState({}, null, '/');
        }
        this.showFeaturedGrid();
        setTimeout(() => {
          const filterRect = this.filterDom.getBoundingClientRect();
          const headerRect = document.getElementsByTagName('header')[0].getBoundingClientRect();
          const heroRect = document.getElementById('hero').getBoundingClientRect();
          const top = heroRect.height + filterRect.height + headerRect.height;
          window.scrollTo({ top, behavior: 'smooth' });
        }, 5);
        return;
      }

      const query = getBlogPostsByCategories(this.locale, this.activeCategories);
      this.postsSearchResult = [...(await this.$sanity.fetch(query))];
      this.showSearchResults();
      setTimeout(() => {
        const filterRect = this.filterDom.getBoundingClientRect();
        const headerRect = document.getElementsByTagName('header')[0].getBoundingClientRect();
        const heroRect = document.getElementById('hero').getBoundingClientRect();
        const top = heroRect.height + filterRect.height + headerRect.height;
        window.scrollTo({ top, behavior: 'smooth' });
      }, 20);
    },
    async onShowFavourites() {
      this.resetCategoryFilters();
      this.resetTextResearch();

      const _ids = this.getFavourites();
      const query = getFavouritesBlogPostQuery(_ids);
      this.postsSearchResult = [...(await this.$sanity.fetch(query))];
      this.showSearchResults();
      this.recalculatePostGridScrollPosition();
    },
    onSearch() {
      // show loading
      if (this.debounceTextSearch) {
        clearTimeout(this.debounceTextSearch);
      }

      // user has deleted the search term
      if (this.searchTerm === '') {
        this.showFeaturedGrid();
        this.recalculatePostGridScrollPosition();
        return;
      }
      this.debounceTextSearch = setTimeout(async () => {
        const query = getBlogPostsByTitle(this.locale, this.searchTerm);
        this.postsSearchResult = [...(await this.$sanity.fetch(query))];
        this.showSearchResults();
        this.recalculatePostGridScrollPosition();
        // hide loading
      }, 500);
    },
    // Utilities
    isCategoryActive(category) {
      return this.activeCategories.some((cat) => cat._id === category._id);
    },
    toggleCategoryFilters() {
      if (this.isLg) {
        this.isCategoryFilterOpen = !this.isCategoryFilterOpen;
        return;
      }

      if (!this.isCategoryFilterOpen) {
        // when opened the filters, no scroll is needed
        this.isCategoryFilterOpen = true;
        return;
      }

      // if user closes the filters, then we have to scroll to results
      const hero = document.getElementById('hero');
      const heroRect = hero.getBoundingClientRect();
      const header = document.getElementById('header');
      const headerRect = header.getBoundingClientRect();

      const top = headerRect.height + heroRect.height - this.initFilterRect.height;
      this.isCategoryFilterOpen = false;
      window.scrollTo({ top, behavior: 'smooth' });
    },
    resetCategoryFilters() {
      this.activeCategories = [];
    },
    resetSearchResults() {
      this.postsSearchResult = [];
    },
    resetFavourites() {
      this.$store.commit('ui/favoriteFilterActivated', false);
    },
    showSearchResults() {
      this.isFeaturedGridActive = false;
      this.isSearchResultGridActive = true;
    },
    showFeaturedGrid() {
      this.isSearchResultGridActive = false;
      this.isFeaturedGridActive = true;
    },
    resetTextResearch() {
      this.searchTerm = '';
    },
    toggleSearchText($event) {
      $event.stopPropagation();
      const isMd = window.innerWidth < 1024;

      if (isMd) {
        this.isTextSearchOpen = !this.isTextSearchOpen;

        this.filterDom.scrollIntoView({ behavior: 'smooth' });
      } else {
        // if desktop
        this.onSearch();
      }
    },
    recalculatePostGridScrollPosition() {
      const filterRect = this.filterDom.getBoundingClientRect();

      // this tollenrance is needed for avoid potential
      // shift caused by programmatic scroll
      const tollerance = 60;
      if (filterRect.top === 0 || filterRect.top < tollerance) {
        // this case means that filter bar is sticky and at top: 0
        // User is scrolling down on research results, after user input
        //  we stick results on top right after the filter bar.
        const headerRect = document.getElementsByTagName('header')[0].getBoundingClientRect();
        const heroRect = document.getElementById('hero').getBoundingClientRect();
        const top = heroRect.height + filterRect.height + headerRect.height;
        window.scrollTo({ top, behavior: 'smooth' });
      } else {
        // this case means that filter bar is NOT sticky and at top: 0
        this.filterDom.scrollIntoView({ behavior: 'smooth' });
      }
    },
    clearFilters() {
      this.resetTextResearch();
      this.resetCategoryFilters();
      this.resetSearchResults();
      this.showFeaturedGrid();
      this.isCategoryFilterOpen = false;
      setTimeout(() => this.recalculatePostGridScrollPosition(), 200);
    }
  }
};
